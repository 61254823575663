import * as React from 'react';
import * as Sentry from '@sentry/browser';
import IdleJs from 'idle-js';
import { I18nextProvider } from 'react-i18next';
import { message } from 'antd';
import { Provider } from 'react-redux';
// import { register } from 'register-service-worker';
import { render } from 'react-dom';
import { resolver } from 'found';

import Router from 'app/Router';
import config from 'config';
import i18n from 'app/i18next';
import store from 'app/redux/store';

const MAX_IDLE_MIN = 15;

message.config({
  duration: 5,
});

const rootEl = document.getElementById('root');

const idle = new IdleJs({
  idle: 1000 * 60 * MAX_IDLE_MIN,
  onIdle() {
    alert(
      `Sorry, we have noticed that you have left the browser idle for more than ${MAX_IDLE_MIN} minutes. Your will be logout.`,
    );
    const originURL = window.location.origin;
    window.location.replace(`${originURL}/logout`);
  },
});
idle.start();

render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <Router matchContext={{ store }} resolver={resolver} />
    </Provider>
  </I18nextProvider>,
  rootEl,
);

if (config.SENTRY_DSN) {
  Sentry.init({ dsn: config.SENTRY_DSN });
}

// register('/service-worker.js', {});
