import { FSA } from 'flux-standard-action';
import { ActionType } from './constants';

export const switchAccountReducer = (state: any = null, action: FSA<string | undefined>) => {
  switch (action.type) {
    case ActionType.SelectAccount:
      return action.payload;

    default:
      return state;
  }
};

export default switchAccountReducer;
