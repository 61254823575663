import * as Sentry from '@sentry/browser';
import baseFetchDriver from 'redux-saga-requests-fetch';
import { call } from 'redux-saga/effects';
import { watchRequests as baseWatchRequests } from 'redux-saga-requests';

import config from 'config';

export const fetchDriver = {
  ...baseFetchDriver,
  getRequestHandlers: (requestInstance) => ({
    sendRequest: ({ url, ...init }) => requestInstance(url, init),
  }),
  getSuccessPayload: (response) => response,
};

export function* watchRequests(common: {} = {}) {
  while (true) {
    try {
      yield call(baseWatchRequests, common);
    } catch (error) {
      // Do nothing
      if (config.SENTRY_DSN) {
        Sentry.captureException(error);
      }
    }
  }
}
