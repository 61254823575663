/**
 * This file is part of the U-Centric project.
 *
 * (c) U-Centric Development Team <dev@ucentric.sisgroup.sg>
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 *
 * @author Lee Siong Chan <leesiong.chan@sisgroup.sg>
 */

import * as React from 'react';
import loadable from 'loadable-components';
import { Logout } from '@ucentric/react-components';
import { hotRouteConfig, makeRouteConfig, Redirect, Route } from 'found';

const ForgotPasswordPage = loadable(() => import('app/pages/ForgotPasswordPage'));
const MaintenanceMessagePage = loadable(() => import('app/pages/MaintenanceMessagePage'));
const LandingPage = loadable(() => import('app/pages/LandingPage'));
const ResetPasswordPage = loadable(() => import('app/pages/ResetPasswordPage'));

const routeConfig = hotRouteConfig(
  makeRouteConfig(
    <Route>
      <Redirect from="/" to="/login" />
      <Route Component={ForgotPasswordPage} path="/forgot-password" />
      <Route Component={ResetPasswordPage} path="/reset-password" />
      <Route Component={LandingPage} path="/login" />
      <Route render={(props) => <Logout {...props} platform="login" />} path="/logout" />
      <Route Component={MaintenanceMessagePage} path="/maintenance-message" />
    </Route>,
  ),
);

export default routeConfig;
