import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resources from '@ucentric/locales';

i18next.use(LanguageDetector).init({
  fallbackLng: 'en',
  resources,
});

export default i18next;
