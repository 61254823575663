import { Actions as FarceActions } from 'farce';
import { put, select, take } from 'redux-saga/effects';

import { ActionType } from './constants';
import { RootState } from 'app/redux/store';

// Watchers
export function* watchAccountSelect(): IterableIterator<any> {
  while (true) {
    const { selectedAccount }: RootState = yield select();
    const { payload } = yield take(ActionType.SelectAccount);

    // Whenever user switch an account, redirect to root!
    if (payload !== selectedAccount) {
      yield put(FarceActions.push('/'));
    }
  }
}
