import * as React from 'react';
import { ExceptionPanel, LoadingPanel } from '@ucentric/react-components';
import { ScrollManager } from 'found-scroll';
import { createConnectedRouter, createRender } from 'found';
// import { hot } from 'react-hot-loader';

import App from 'app/components/layouts/App';

const render = createRender({
  renderError: ({ error }) => <ExceptionPanel statusCode={error.status} />,
  renderPending: () => (
    <App>
      <LoadingPanel />
    </App>
  ),
});

const Router = createConnectedRouter({
  render: (props) => <ScrollManager renderArgs={props}>{render(props)}</ScrollManager>,
});

export default Router;
// export default hot(module)(Router);
