import createSagaMiddleware from 'redux-saga';
import { Actions as FarceActions, BrowserProtocol, createHistoryEnhancer, queryMiddleware } from 'farce';
import { StateType } from 'typesafe-actions';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { createMatchEnhancer, foundReducer, Matcher } from 'found';
import {
  authReducer,
  createRequestPromiseMiddleware,
  entitiesReducer,
  requestsReducer,
  requestStatusesReducer,
  statusesReducer,
  subrequestStatusesReducer,
} from '@ucentric/redux';
import { middleware as thunkMiddleware } from 'redux-saga-thunk';

import config from 'config';
import rootSaga from 'app/redux/saga';
import routeConfig from 'app/route-config';
import switchAccountReducer from './modules/switch-account/reducer';

export type RootState = StateType<typeof rootReducer>;

const loggerMiddleware = createLogger({ collapsed: true });
const requestPromiseMiddleware = createRequestPromiseMiddleware();
const sagaMiddleware = createSagaMiddleware();

// @note ordering matter.
const middlewares = [];

if (config.NODE_ENV === 'development') {
  middlewares.push(loggerMiddleware);
}

const enhancer = compose(
  applyMiddleware(thunkMiddleware, requestPromiseMiddleware, sagaMiddleware),
  createHistoryEnhancer({
    middlewares: [queryMiddleware],
    protocol: new BrowserProtocol(),
  }),
  createMatchEnhancer(new Matcher(routeConfig)),
  applyMiddleware(...middlewares),
);

const rootReducer = combineReducers({
  auth: authReducer,
  entities: entitiesReducer,
  found: foundReducer,
  requestStatuses: requestStatusesReducer,
  requests: requestsReducer,
  selectedAccount: switchAccountReducer,
  statuses: statusesReducer,
  subrequestStatuses: subrequestStatusesReducer,
});

const initialState = {};

const store = createStore(rootReducer, initialState, enhancer);

store.dispatch(FarceActions.init());

sagaMiddleware.run(rootSaga);

export default store;
