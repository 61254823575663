/**
 * This file is part of the U-Centric project.
 *
 * (c) U-Centric Development Team <dev@ucentric.sisgroup.sg>
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 *
 * @author Lee Siong Chan <leesiong.chan@sisgroup.sg>
 */

import * as React from 'react';
import { Layout } from 'antd';
import { EnvironmentWarningAlert, ErrorBoundary } from '@ucentric/react-components';

import config from 'config';
import styles from './styles.module.css';
import { AppProps } from './properties';

const App = ({ children }: AppProps) => {

  return (
    <>

      <Layout className={styles.root}>

        <Layout>

          <Layout.Content>
            <ErrorBoundary>{children}</ErrorBoundary>
          </Layout.Content>

          <Layout.Footer style={{ textAlign: 'center' }}>
            {config.COMPANY.NAME} &copy; {new Date().getFullYear().toString()} Powered by {config.COPYRIGHT}
          </Layout.Footer>
        </Layout>
      </Layout>

      {config.ENABLE_ENVIRONMENT_WARNING_ALERT && <EnvironmentWarningAlert />}
    </>
  );
};

export default App;
